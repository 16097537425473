.about-us {
  padding: 0 30px;
}
.about-us .title {
  font-size: 22px;
  line-height: 1;
  text-align: right;
}
.about-us {
  width: 100%;
}
.about-us .content {
  margin-top: 15px;
  width: 100%;
}
.about-us .content .content-left,
.about-us .content .content-right {
  width: 50%;
  display: inline-block;
  float: left;
}

.about-us .content-right {
  text-indent: 10px;
}
.about-us .footer {
  clear: both;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.about-us .footer img {
  width: 120px;
  margin-top: 80px;
}
