.home-container {
  padding: 20px 60px;
}
.home-container .home-left {
  width: 420px;
  float: left;
}

.home-container .home-left img {
  margin-top: 40px;
  width: 340px;
}

.home-container .home-right {
  float: left;
}

.home-container .home-right .info {
  font-size: 14px;
  font-style: italic;
  margin-top: 220px;
}

.home-container .home-right .desc {
  font-size: 16px;
  margin-top: 20px;
}
