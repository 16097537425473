.header {
  height: 200px;
  overflow: hidden;
  padding: 15px 0;
}
.logo {
  width: 360px;
  height: auto;
}
.subtitle {
  display: inline-block;
  margin-top: 12px;
  font-size: 12px;
  color: #06b0f0;
  line-height: 1;
}
.first-line,
.second-line {
  display: flex;
}
.first-line-left {
  display: inline-block;
  width: 780px;
}
.first-line .menu {
  border-bottom: 1px solid #ffffff;
}
.menu {
  box-sizing: border-box;
  color: #fff;
  display: inline-block;
  width: 130px;
  height: 34px;
  text-align: center;
  line-height: 34px;
  background-color: #06b0f0;
  text-decoration: none;
  cursor: pointer;
}
.menu:not(:last-child) {
  border-right: 1px solid white;
}

.menu.active,
.menu:hover {
  background-color: #fff;
  color: #06b0f0;
  border: 1px solid #06b0f0;
  box-shadow: #06b0f0 0px 0px 5px 2px;
}
