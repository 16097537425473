.App {
  width: 1200px;
  padding: 0 15px;
  color: #06b0f0;
  box-sizing: border-box;
  text-align: left;
  margin:0 auto;
}
p{
  line-height: 1.5;
}