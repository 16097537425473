.notfound {
  padding-top: 100px;
  text-align: center;
}
.notfound h1 {
  font-size: 80px;
  font-weight: normal;
}
.notfound p {
  font-size: 26px;
  margin-top: 20px;
}
