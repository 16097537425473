.qc-container {
  padding: 0 30px;
  font-size: 14px;
}
.qc-content {
  padding-top: 30px;
  display: flex;
}
.qc-content .qc-left {
  width: 600px;
}

.qc-left .qc01 {
  width: 130px;
}

.qc-left .qc02 {
  width: 500px;
  margin-top: 100px;
  margin-left: 110px;
}

.qc-left .text {
  margin-left: 130px;
}
.qc-right {
  display: flex;
  justify-content: flex-end;
  flex: 1;
}
.qc-right img {
  width: 420px;
}
