.pa-container {
  padding: 0 60px;
  clear: both;
  overflow: hidden;
}
.pa-container .pa-table {
  width: 100%;
  margin-top: 40px;
}

.pa-container .pa-table th,
.pa-container .pa-table td {
  border-left: 1px solid #06b0f0;
  border-top: 1px solid #06b0f0;
  text-align: center;
  vertical-align: middle;
  font-weight: normal;
}

.pa-container .pa-table tr:last-child th,
.pa-container .pa-table tr:last-child td {
  border-bottom: 1px solid #06b0f0;
}

.pa-container .pa-table th:last-child,
.pa-container .pa-table td:last-child {
  border-right: 1px solid #06b0f0;
}
.border-bottom-1px {
  border-bottom: 1px solid #06b0f0;
}

.border-left-1px {
  border-left: 1px solid #06b0f0;
}
