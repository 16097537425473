.a-container {
  font-size: 14px;
  padding: 0 60px;
}
.a-container .a-content {
  padding-top: 30px;
}
.a-container .a-content .a-left {
  width: 65%;
  float: left;
}

.a-container .a-content .a-right {
  width: 35%;
  overflow: hidden;
  float: left;
  text-align: center;
}
.a-content .a-right img {
  width: 200px;
}

.a-content .a-right img.lg {
  width: 400px;
}

.a-content .a-right img.md {
  width: 260px;
}

.a-content .a-right img.auto {
  width: auto;
}

.a-content .a-right .desc {
  display: inline-block;
  width: 200px;
  text-align: center;
  box-sizing: border-box;
}

.a-content .a-left p.indent {
  text-indent: 4em;
}
