.forstaff-container {
  text-align: center;
}
.forstaff-container form {
  overflow: hidden;
  margin: 100px auto 40px auto;
  color: #ffffff;
  background-color: #06b0f0;
  width: 400px;
  height: 240px;
  border-radius: 10px;
  text-align: left;
}

.forstaff-container form .item {
  margin-top: 30px;
  display: flex;
  align-items: center;
}

.forstaff-container form .label {
  display: inline-block;
  width: 100px;
  text-align: right;
  padding: 5px 10px;
  font-size: 20px;
}

.forstaff-container form input {
  color: #06b0f0;
  text-indent: 10px;
  width: 265px;
  height: 40px;
  font-size: 20px;
  border: #06b0f0 1px solid;
}

.forstaff-container form input:placeholder-shown,
.forstaff-container form input::placeholder {
  font-size: 16px;
  color: #999999;
}

.forstaff-container form .submit {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}

.forstaff-container form .submit button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 40px;
  background-color: #ffffff;
  border: 1px solid #17b3ff;
  color: #06b0f0;
  font-weight: bold;
  font-size: 14px;
}

.forstaff-container form .submit button:hover {
  border: none;
  font-size: 15px;
}

.forstaff-container form .submit button:active {
  border: 1px solid #17b3ff;
  font-size: 14px;
}
